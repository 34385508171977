import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";
import { addStatisticsUsersBalance } from "../../actions/statisticsUsersAddBalance";
import { StatisticsUsersAddBalanceView } from "./View";

export const StatisticsUsersAddBalance = () => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState("");
  const [sum, setSum] = useState(200);

  const { isLoaded, isLoading, isError, errorMessage } = useSelector(
    ({
      statisticsUsersAddBalance: { request: statisticsUsersAddBalance },
    }) => ({
      isLoaded: isRequestSuccess(statisticsUsersAddBalance),
      isLoading: isRequestPending(statisticsUsersAddBalance),
      isError: isRequestError(statisticsUsersAddBalance),
      errorMessage: getErrorMessage(statisticsUsersAddBalance),
    })
  );

  const handleSubmit = () => {
    const performedUsers = users.split(",").map((item) => item.trim());

    dispatch(
      addStatisticsUsersBalance({
        usernames: performedUsers,
        sum,
      })
    );
  };

  const getLoading = () => {
    return isLoading;
  };

  const handleChangeUsers = async (e) => {
    const value = e.target.value;

    setUsers(value);
  };

  const handleChangeSum = async (e) => {
    const value = e.target.value;

    setSum(value);
  };

  const isButtonDisabled = !users;

  return (
    <StatisticsUsersAddBalanceView
      loading={getLoading()}
      isButtonDisabled={isButtonDisabled}
      handleSubmit={handleSubmit}
      error={isError}
      errorMessage={errorMessage}
      users={users}
      handleChangeUsers={handleChangeUsers}
      sum={sum}
      handleChangeSum={handleChangeSum}
      isLoaded={isLoaded}
    />
  );
};
